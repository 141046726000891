<template>
  <div>
    <info-card v-if="infoCardsContent" :infoCardsContent="infoCardsContent" />
  </div>
</template>

<script>
import InfoCard from "./InfoCard";
import { WEBSITE_BUILDER_SECTIONS } from "@/config/constants";

export default {
  name: "website-therapy",
  components: {
    "info-card": InfoCard
  },
  props: {
    therapySections: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      infoCardsContent: null
    };
  },
  watch: {
    therapySections(newValue) {
      newValue.forEach(section => {
        if (section.id === WEBSITE_BUILDER_SECTIONS.THERAPIES.id) {
          this.infoCardsContent = section.websiteContents;
        }
      });
    }
  }
};
</script>

<style scoped></style>
