<template>
  <website-container-therapy-view />
</template>

<script>
import WebsiteContainerTherapyView from "@/components/website/WebsiteContainerTherapy.vue";
export default {
  name: "website-container-therapy",
  components: {
    "website-container-therapy-view": WebsiteContainerTherapyView
  },
  data() {
    return {};
  }
};
</script>

<style></style>
